import { h, Component } from 'preact';

import { useEffect, useState } from "preact/hooks";

import moment from "moment";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsTimeline from "highcharts/modules/timeline";

highchartsTimeline(Highcharts);

const ukrepi = [
  {
    "UKREP": "Omejitev izvajanja zdravstveniih storitev",
    "DATUM_ZV": "20/03/2020",
    "DATUM_O": "20/03/2020",
    "NASLOV": "Odlok o začasnih ukrepih na področju zdravstvene dejavnosti zaradi zajezitve in obvladovanja epidemije COVID-19",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2026"
  },
  {
    "UKREP": "Omejitev javnega prevoza",
    "DATUM_ZV": "16/03/2020",
    "DATUM_O": "15/03/2020",
    "NASLOV": "Odlok o začasni prepovedi in omejitvah javnega prevoza potnikov v Republiki Sloveniji",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2008"
  },
  {
    "UKREP": "Omejitev poslovanja sodišč",
    "DATUM_ZV": "13/03/2020",
    "DATUM_O": "13/03/2020",
    "NASLOV": "Odredba o posebnih ukrepih zaradi nastanka pogojev iz prvega odstavka 83.a člena Zakona o sodiščih",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODRE2556"
  },
  {
    "UKREP": "Omejitev potovanj zdravstvenim delavcem",
    "DATUM_ZV": "12/03/2020",
    "DATUM_O": "12/03/2020",
    "NASLOV": "Odredba o omejitvi določenih pravic zdravstvenih delavcev in zdravstvenih sodelavcev",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODRE2548"
  },
  {
    "UKREP": "Omejitev prodaje blaga in storitev",
    "DATUM_ZV": "16/03/2020",
    "DATUM_O": "15/03/2020",
    "NASLOV": "Odlok o začasni prepovedi ponujanja in prodajanja blaga in storitev potrošnikov v Republiki Sloveniji",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2010"
  },
  {
    "UKREP": "Omejitev vstopa iz Italije",
    "DATUM_ZV": "19/03/2020",
    "DATUM_O": "18/03/2020",
    "NASLOV": "Odlok o določitvi pogojev vstopa v Republiko Slovenijo iz Italijanske republike zaradi zajezitve in obvladovanja nalezljive bolezni",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2020"
  },
  {
    "UKREP": "Omejitev zračnih prevozov",
    "DATUM_ZV": "17/03/2020",
    "DATUM_O": "16/03/2020",
    "NASLOV": "Odlok o prepovedi opravljanja zračnih prevozov v Republiki Sloveniji",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2011"
  },
  {
    "UKREP": "Prepoved gibanja na javnih mestih",
    "DATUM_ZV": "20/03/2020",
    "DATUM_O": "19/03/2020",
    "NASLOV": "Odlok o začasni splošni prepovedi gibanja in zbiranja ljudi na javnih mestih in površinah v Republiki Sloveniji",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2018"
  },
  {
    "UKREP": "Prepoved izvajanja teh. pregledov",
    "DATUM_ZV": "17/03/2020",
    "DATUM_O": "16/03/2020",
    "NASLOV": "Odlok o začasni prepovedi izvajanja tehničnih pregledov in drugih postopkov v zvezi z registracijo motornih vozil ter dela v tahografskih delavnicah v Republiki Sloveniji",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2014"
  },
  {
    "UKREP": "Razglasitev epidemije",
    "DATUM_ZV": "12/03/2020",
    "DATUM_O": "12/03/2020",
    "NASLOV": "Odredba o razglasitvi epidemije nalezljive bolezni SARS-CoV-2 (COVID-19) na območju Republike Slovenije",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODRE2550"
  },
  {
    "UKREP": "Sprejetje ukrepov iz ZNB za COVID-19",
    "DATUM_ZV": "13/03/2020",
    "DATUM_O": "14/03/2020",
    "NASLOV": "Sklep o uporabi ukrepov, ki jih določa Zakon o nalezljivih boleznih, pri epidemiji COVID-19",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=SKLE12011"
  },
  {
    "UKREP": "Zaprtje avtošol, prepoved prodaje, servisa in najemov vozil ter prevozov",
    "DATUM_ZV": "18/03/2020",
    "DATUM_O": "17/03/2020",
    "NASLOV": "Odlok o začasni prepovedi ponujanja in prodajanja blaga in storitev neposredno potrošnikom šolam vožnje in pooblaščenim organizacijam s področja voznikov in vozil v Republiki Sloveniji",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2015"
  },
  {
    "UKREP": "Zaprtje maloobmejnih prehodov s Hrvaško",
    "DATUM_ZV": "18/03/2020",
    "DATUM_O": "17/03/2020",
    "NASLOV": "Odlok o začasnem prenehanju obratovanja mejnih prehodov za obmejni promet na meji z Republiko Hrvaško",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2017"
  },
  {
    "UKREP": "Zaprtje vzgojnih in izobraževalnih ustanov",
    "DATUM_ZV": "16/03/2020",
    "DATUM_O": "15/03/2020",
    "NASLOV": "Odlok o začasni prepovedi zbiranja ljudi v zavodih s področja vzgoje in izobraževanja ter univerzah in samostojnih visokošolskih zavodih",
    "PISRS_URL": "http://www.pisrs.si/Pis.web/pregledPredpisa?id=ODLO2009"
  }
];

const chartOptions = {
  chart: {
    backgroundColor: "transparent"
  },
  legend: {
    enabled: false
  },
  xAxis: {
    type: "date",
    title: {
      text: "Dan"
    },
    labels: {
      formatter() {
        return moment(this.value).format("DD MMM");
      }
    }
  },
  yAxis: {
    title: {
      text: "Št. okuženih"
    }
  },
  title: {
    text: null
  },
  series: [{
    type: 'line',
    data: []
  }],
  tooltip: {
    formatter() {
      if (this.series.name === "Series 2") {
        return `${Highcharts.dateFormat("%d %b",moment(this.x).toDate())} <br /> ${this.point.text}`;
      }
      return `${moment(this.x).format("DD MMM")} <br> Št. okužb: ${this.y}`;
    }
  }
};

const computeData = (data) => {
  const parsedData = data.filter((entry) =>
    moment(entry.year + "-" + entry.month + "-" + entry.day) >
		moment("2020-03-04")
  ).map((p) => [
    moment(p.year + "-" + p.month + "-" + p.day).valueOf(),
    p.positiveTests
  ]);
  return parsedData;
};

const computeTimelineData = (data) => {
  return data.map((entry) => ({
    x: moment(entry.datum, "DD-MM-YYYY").valueOf(),
    text: entry.ukrepi.join("<br />"),
    title: "⚠️"
  }));
};

const computeLaws = (data) => {
  const laws = [];
  data.forEach((ukrep) => {
    const ukrepExisting = laws.filter((p) => p.datum === ukrep.DATUM_ZV)[0];
    if (!ukrepExisting) {
      laws.push({
        datum: ukrep.DATUM_ZV,
        ukrepi: [ukrep.UKREP]
      });
    } else {
      ukrepExisting.ukrepi.push(ukrep.UKREP);
    }
  });
  return laws;
};

export default ({data}) => {
  const [covidData, setData] = useState({
    data: null,
    options: chartOptions
  });

  useEffect(() => {
    setData((state) => {
      return {
        ...state,
        options: {
          series: [{
            name: "Potrjeni primeri",
            data: computeData(data)
          },
          {
            type: "flags",
            data: computeTimelineData(computeLaws(ukrepi)),
            shape: 'squarepin',
            showInLegend: false,
            tooltip: {
              enabled: true
            },
            color: "#000000"
          }]
        }
      };
    });
  }, [data]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={covidData.options}
      />
    </div>
  );
};
