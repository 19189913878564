import moment from "moment";

export const computeDataForHeatMap = (data, date, flattened) => {
  const dates = data.map((p) => moment(p.year + "-" + p.month + "-" + p.day).toDate());

  if (date > dates[dates.length - 1]) {
    date = dates[dates.length - 1];
  }

  let flatFeatures = [];

  const currentData = data.filter((p) => {
    return moment(date).startOf('day').subtract(1, "days").diff(
      moment(p.year + "-" + p.month + "-" + p.day).startOf('day'), "days") === 0;
  })[0];
  const regions = Object.keys(currentData.regions);
  const cities = regions.map((p) => currentData.regions[p]);
  const citiesFlat = cities.map((p) => Object.entries(p).map(( [k, v] ) => ({ name: k, total: v }))).flat(2);

  flattened.features.forEach((feature) => {
    const city = citiesFlat.filter((p) => feature.properties.name.replace(/ /g, "_").toLowerCase().indexOf(p.name) !== -1)[0];
    if (city) {
      flatFeatures.push({
        name: city.name,
        positive: city.total,
        featureName: feature.properties.name
      });
    }
  });

  return flatFeatures;
};

export const randomColor = (positive, sum) => {
  const quantize = 3 * positive / sum * 100;
  return quantize > 0.5 ? quantize : 0.5;
};

export const getBBox = (coords) => {
  const bbox = [ Number.POSITIVE_INFINITY,Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];
  return coords.reduce((prev, coord) => {
    return [
      Math.min(coord[0], prev[0]),
      Math.min(coord[1], prev[1]),
      Math.max(coord[0], prev[2]),
      Math.max(coord[1], prev[3])
    ];
  }, bbox);
};
