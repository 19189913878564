// import 'promise-polyfill';
// import 'isomorphic-fetch';
import "regenerator-runtime/runtime.js";
import { h, render } from 'preact';
import './style.css';

import App from "./components/app";

let root;
function init() {
  root = render(<App />, document.body, root);
}

// register ServiceWorker via OfflinePlugin, for prod only:
if (process.env.NODE_ENV==='production') {
  require('./pwa');
}

// in development, set up HMR:
if (module.hot) {
  module.hot.accept('./components/app', () => requestAnimationFrame(init) );
}

init();
