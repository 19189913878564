import { h } from 'preact';
import osme from "osme";
import { useState, useEffect } from "preact/hooks";
import slovenia from "../../assets/slovenia";
import flatten from "geojson-flatten";
import moment from "moment";
import { OlMap } from "openlayers-react";
import OlView from 'ol/View';
import { transform } from 'ol/proj';
import {computeSliderLabelsRegions} from "../../utils";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Heatmap from "ol/layer/Heatmap";
import { defaults as defaultInteractions } from "ol/interaction";
import obcine from "../../assets/obcine";
import { computeDataForHeatMap, randomColor, getBBox } from "./util";
import { center } from "../../utils";
import Slider from "rc-slider";

const json = osme.parseData(slovenia);
const flattened = flatten(json);

export default () => {
  const [data, setData] = useState(null);
  const [map, setMap] = useState(null);
  useEffect(async () => {
    const result = await fetch("https://covid19.rthand.com/api/regions");
    const data = await result.json();
    setData({
      data,
      currentSliderValue: data ?
        Object.keys(computeSliderLabelsRegions(data)).length - 1 : null,
      date: moment().subtract(1, "days").toDate(),
      computedData: computeDataForHeatMap(data, moment().toDate(), flattened)
    });
  }, []);

  useEffect(() => {
    if (data) {
      setMap(computeMapOptions());
    }
  }, [data]);

  const setDate = (value) => {
    const labels = computeSliderLabelsRegions(data.data);
    const date = labels[value].date;
    const index = Object.keys(labels).map((p) => labels[p].date).indexOf(date);
    setData((data) => {
      return {
        ...data,
        currentSliderValue: index,
        date,
        computedData: computeDataForHeatMap(data.data, date, flattened)
      };
    });
    setMap(computeMapOptions());
  };

  const playAgeInTime = () => {
    if (data.isPlaying) {
      return false;
    }

    let date = moment("2020-03-13");
    let lastDate = moment(data.data[data.data.length - 1].year + "-" +
      data.data[data.data.length - 1].month + "-" +
      data.data[data.data.length - 1].day).hours(0);
    const labels = computeSliderLabelsRegions(data.data);
    const interval = setInterval(() => {
      const index = Object.keys(labels).map((p) => moment(labels[p].date).hours(0).valueOf()).indexOf(moment(date).valueOf());
      setData((data) => {
        return {
          ...data,
          currentSliderValue: index,
          date,
          computedData: computeDataForHeatMap(data.data, date, flattened),
          isPlaying: true
        };
      });

      if (lastDate.valueOf() === moment(date).valueOf()) {
        setData((data) => ({...data, isPlaying: false}));
        return clearInterval(interval);
      }

      date = moment(date).add(1, "days").toDate();
    }, 1000);
  };

  const getStyleForRegion = () => {
    return new Style({
      stroke: new Stroke({
        color: 'red',
        width: 1
      })
    });
  };

  const format = new GeoJSON({
    featureProjection:"EPSG:3857"
  });

  const coords = (new GeoJSON()).readFeatures(json);
  const featuresCenters = coords.map((p) => {
    const bounds = p.getGeometry();
    return { type: "Feature", properties: { name: p.values_.name}, geometry: { type: "Point", coordinates: [...center(...bounds.getCoordinates()), 10] } };
  });

  const coordsFlattened = featuresCenters.map((p) => p.geometry.coordinates).flat(1);
  const bbox = getBBox(coordsFlattened);

  const computeWeight = (entry) => {
    const region = data.computedData.filter((p) => entry.values_.name === p.featureName)[0];
    if (region) {
      const regions = obcine.filter((p) => region.name === p.obcina_slug)[0];
      return randomColor(region.positive, regions.st_prebivalcev);
    }
    return 0;
  };

  const computeMapOptions = () => {
    return {
      controls: [],
      layers: [
        new VectorLayer({
          source: new VectorSource({
            features: format.readFeatures(json)
          }),
          style: getStyleForRegion,
          opacity: 0.5
        }),
        new Heatmap({
          source: new VectorSource({
            features: format.readFeatures({type: "FeatureCollection", features: featuresCenters, bbox}),
            format
          }),
          blur: 20,
          radius: 20,
          weight: computeWeight
        })
      ],
      view: new OlView({
        center: transform([15.1720307, 46.1456719], 'EPSG:4326', 'EPSG:3857'),
        zoom: 8,
        pitch: 3
      }),
      interactions: defaultInteractions({
        doubleClickZoom: false,
        dragAndDrop: false,
        dragPan: false,
        keyboardPan: false,
        keyboardZoom: false,
        mouseWheelZoom: false,
        pointer: false,
        select: false
      })
    };
  };

  return (
    <div>
      { data &&
        <div>
          <div className={"map-container"}>
            {map && <OlMap initialMapOptions={map}/> }
          </div>
        </div>
      }
    </div>
  );
};

