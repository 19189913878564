import { h } from 'preact';

import { useEffect, useState } from "preact/hooks";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const computeData = (data, type) => {
  const parsedData = data.filter((entry) =>
    moment(entry.year + "-" + entry.month + "-" + entry.day) >
    moment("2020-03-04")
  ).map((p) => [
    moment(p.year + "-" + p.month + "-" + p.day).valueOf(),
    p.statePerTreatment[type] ? p.statePerTreatment[type] : 0
  ]);
  return parsedData;
};

export default ({data}) => {
  const [covidData, setData] = useState({
    data: null,
    options: {}
  });

  useEffect(() => {
    setData((state) => {
      return {
        ...state,
        options: {
          title: {
            text: null
          },
          tooltip: {
            useHTML: true,
            formatter() {
              return '<small>' + Highcharts.dateFormat("%d %b", moment(this.key).toDate()) + '</small><br />Št. primerov: ' + this.point.y;
            }
          },
          chart: {
            backgroundColor: "transparent"
          },
          yAxis: [{
            title: {
              text: "Število primerov"
            }
          }],
          xAxis: {
            type: "date",
            labels: {
              align: "center",
              formatter() {
                return Highcharts.dateFormat("%b %d", moment(this.value).toDate());
              }
            }
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: !1
              }
            },
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
                useHTML: true,
                style: {
                  color: "contrast",
                  textOutline: false
                },
                formatter() {
                  return "<div style='border: 1px solid #000; padding-right: 2px; padding-left: 2px; background-color: #f0f0f0; color: #000;'>" + this.y + "</div>";
                }
              }
            }
          },
          series: [
            {
              name: "V kritičnem stanju",
              data: computeData(data, "critical"),
              type: 'column',
              color: "#ff0000"
            },
            {
              name: "Hospitalizirani",
              data: computeData(data, "inHospital"),
              type: 'column',
              color: "#638fb9"
            },
            {
              name: "Intenzivna nega",
              data: computeData(data, "inICU"),
              type: 'column',
              color: '#000'
            }],
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom"
                }
              }
            }]
          }
        }
      };
    });
  }, [data]);

  console.log(covidData);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={covidData.options}
      />
    </div>
  );
};
