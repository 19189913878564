import { h, Component } from 'preact';

export default class Header extends Component {
  render() {
    return (
		  <header className={"header"}>
        <div className={"header-items"}>
          <h4 className={"item-first"}>
            <a href={"https://ustavimokorono.si"}>
	            <img src={"https://ustavimokorono.si/wp-content/uploads/2020/04/logo.png"} className={"header-img"}/>
            </a>
	            <div className={"beta"}>
								monitor
	            </div>
          </h4>
        </div>
      </header>
    );
  }
}
