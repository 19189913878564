const DATE_DATA_START = "2020-03-13";
import moment from "moment";

export const createDate = (date) => {
  return new Date(date.split("-")[0] + "/" + date.split("-")[1] + "/" + date.split("-")[2]);
};

export const computeSliderLabelsAge = (data) => {
  const labels = {};
  data
    .filter((p) => moment(p.date).toDate() >= createDate(DATE_DATA_START))
    .slice(0, -1)
    .forEach((p, i) => {
      labels[i] = {
        label: moment(p.date).format("DD.MM"),
        date: moment(p.date).toDate()
      };
    });
  return labels;
};

export const computeSliderLabelsRegions = (data) => {
  const labels = {};
  data
    .filter((p) => moment(p.year + "-" + p.month + "-" + p.day).toDate() >= createDate(DATE_DATA_START))
    .forEach((p, i) => {
      labels[i] = {
        label: `${moment(p.year + "-" + p.month + "-" + p.day).get("date")}. ${moment(p.year + "-" + p.month + "-" + p.day).get("month") + 1}`,
        date: moment(p.year + "-" + p.month + "-" + p.day).toDate()
      };
    });
  return labels;
};

export const maxSlider = (data) => data.jsonSloData
  .filter((p) => createDate(p.date) >= createDate(DATE_DATA_START)).length - 1;

export const center = (arr) =>
{
  let minX = 0,
    maxX = 0,
    minY = 0,
    maxY = 0;
  for (let i = 0; i < arr.length; i++)
  {
    minX = (arr[i][0] < minX || minX === 0) ? arr[i][0] : minX;
    maxX = (arr[i][0] > maxX || maxX === 0) ? arr[i][0] : maxX;
    minY = (arr[i][1] < minY || minY === 0) ? arr[i][1] : minY;
    maxY = (arr[i][1] > maxY || maxY === 0) ? arr[i][1] : maxY;
  }

  return [(minX + maxX) / 2, (minY + maxY) / 2];
};

export const month = (m) => {
  const months = ["januar", "februar", "marec", "april", "maj", "junij", "julij", "avgust", "september", "oktober", "november", "december"];
  return months[m];
};
