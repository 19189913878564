import { h } from 'preact';
import { useEffect, useState } from "preact/hooks";

import moment from "moment";

import Highcharts from "highcharts";
import highchartSeriesLabel from "highcharts/modules/series-label";
import HighchartsReact from "highcharts-react-official";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { createDate, computeSliderLabelsAge, maxSlider, month } from "../../utils";

import OkuzeniDaily from "../okuzeni-daily";
import Okuzeni from "../okuzeni-and-laws";
import Hospitalized from "../hospitalized";

highchartSeriesLabel(Highcharts);

import Map from "../map";

const sum = (data) => {
  let value = 0;
  for (let i = 0; i <= data.length - 1; i++) {
    value += data[i];
  }
  return value;
};

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const countriesToShow = ["Italy", "US", "Spain", "China", "Germany", "Slovenia", "Sweden"];
const countriesLabels = ["Italija", "ZDA", "Španija", "Kitajska", "Nemčija", "Slovenija", "Švedska"];

const transpose = (data) =>  {
  const points = [];
  countriesToShow.forEach((country, index) => {
    const dataTop10 = data[country].filter((p) => p.deaths > 10);
    const dataFiltered = dataTop10
      .map((p) => [createDate(p.date), p.deaths]);
    points.push({data: dataFiltered, name: countriesLabels[index]});
  });
  return points;
};
const computeSloData = (data, date) => {
  const totalByAgeMale = [];
  const totalByAgeFemale = [];
  const ranges = ["0-4", "5-14", "15-24", "25-34", "35-44", "45-54", "55-64", "65-74", "75-84", "85+", "todate"];
  let p, rangeAge;

  if (date) {
    p = data.filter((p) => p.date === moment(date).format("YYYY-MM-DD"))[0];
  } else {
    p = data[data.length - 2];
  }

  rangeAge = ranges;

  const keys = Object.keys(p);

  keys.forEach((key) => {
    if (p[key] === "") { p[key] = 0;}
    if (key.indexOf("age.male") !== -1) {
      if (rangeAge.indexOf(key.split(".")[2]) !== -1) {
        totalByAgeMale.push({
          ageRange: key.split(".")[2],
          total: parseInt(p[key], 0)
        });
      }
    }
    else if (key.indexOf("age.female") !== -1) {
      if (rangeAge.indexOf(key.split(".")[2]) !== -1) {
        totalByAgeFemale.push({
          ageRange: key.split(".")[2],
          total: parseInt(p[key], 0)
        });
      }
    }
  });

  const total =
		sum(totalByAgeMale.filter((p) => p.ageRange !== "todate").map((p) => p.total)) +
		sum(totalByAgeFemale.filter((p) => p.ageRange !== "todate").map((p) => p.total));

  return {
    total,
    totalByAgeFemale,
    totalByAgeMale
  };
};
const computeAgeData = (data) => {
  return [
    {
      name: "Moški",
      data: data.totalByAgeMale.filter((p) => p.ageRange !== "todate").map((p) => p.total),
      color: "#ff5a5a"
    },
    {
      name: "Ženske",
      data: data.totalByAgeFemale.filter((p) => p.ageRange !== "todate").map((p) => p.total),
      color: "#638fb9"
    }];
};
const computeCategories = (data) => {
  return data.filter((p) => p.ageRange !== "todate").map((p) => p.ageRange);
};

const chartOptionsDeaths = {
  title: null,
  chart: {
    backgroundColor: "transparent"
  },
  tooltip: {
    headerFormat: "{point.value}"
  },
  yAxis: {
    title: {
      text: "Število smrti"
    },
    type: 'logarithmic',
    min: 10,
    minorTickInterval: 0.8
  },
  xAxis: {
    type: "date",
    title: {
      text: "Število dni od 10. smrti"
    },
    labels: {
      align: "center"
    },
    max: 50,
    tickInterval: 2
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle"
  },
  plotOptions: {
    line: {
      label: {
        enabled: true,
        onArea: false
      }
    },
    series: {
      label: {
        connectorAllowed: !1
      }
    }
  },
  series: [],
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom"
        }
      }
    }]
  }
};
const chartOptionsAge = {
  title: null,
  tooltip: {
    formatter() {
      return '<small>Št. okuženih: ' + this.point.y + '</small>';
    }
  },
  chart: {
    backgroundColor: "transparent",
    type: "bar"
  },
  yAxis: {
    title: {
      text: "Število okuženih"
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color: 'gray'
      },
      formatter() {
        return this.total;
      }
    }
  },
  xAxis: {
    title: {
      text: "Starost"
    },
    categories: [],
    labels: {
      align: "center"
    },
    gridLineWidth: 1
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle"
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      label: {
        connectorAllowed: !1
      },
      dataLabels: {
        enabled: true,
        style: {
          textOutline: false
        },
        formatter() {
          return this.y + " (" + Math.round(this.percentage) + "%)";
        }
      }
    }
  },
  series: [],
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false
            }
          }
        },
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom"
        }
      }
    }]
  }
};

const dashedLines = [
  {
    type: 'spline',
    id: 'line-1',
    dashStyle: 'dash',
    name: 'Smrti se podvojijo vsak dan',
    color: "#aaa4a4",
    data: [{x:0 , y: 10}, {x: 10, y: 10000}],
    tooltip: {
      enabled: true,
      pointFormatter: () => {
        return "Smrti se podvojijo vsak dan";
      }
    }
  },
  {
    type: 'spline',
    id: 'line-2',
    dashStyle: 'dash',
    name: '... vsak 2. dan',
    color: "#aaa4a4",
    data: [{x:0 , y: 10}, {x: 20, y: 10000}],
    tooltip: {
      pointFormatter: () => {
        return "Smrti se podvojijo vsak 2. dan";
      }
    }
  },
  {
    type: 'spline',
    id: 'line-3',
    dashStyle: 'dash',
    name: '... vsak 3. dan',
    color: "#aaa4a4",
    data: [{x:0 , y: 10}, {x: 30, y: 10000}],
    tooltip: {
      pointFormatter: () => {
        return "Smrti se podvojijo vsak 3. dan";
      }
    }
  },
  {
    type: 'spline',
    id: 'line-4',
    dashStyle: 'dash',
    name: '... vsak teden',
    color: "#aaa4a4",
    data: [{x: 0, y: 10}, {x: 70, y: 10000}],
    tooltip: {
      pointFormatter () {
        return "Smrti se podvojijo vsak teden";
      }
    }
  }
];

export default () => {
  const [covidData, setCovidData] = useState({
    covidSeries: [], chartOptionsDeaths, chartOptionsAge, fetched: false });
  useEffect(async () => {
    const resultCovid = await fetch(
      'https://pomber.github.io/covid19/timeseries.json',
    );

    const resultSloData = await fetch(
      "https://ustavimokorono.si/podatki.php"
    );

    const resultSloDataApi = await fetch(
      "https://covid19.rthand.com/api/stats"
    );
    const jsonCovid19 = await resultCovid.json();
    const jsonSloData = await resultSloData.json();
    const jsonSloDataApi = await resultSloDataApi.json();
    const computedSloData = computeSloData(jsonSloData);
    const computedAgeData = computeAgeData(computedSloData);
    setCovidData((data) => {
      return {
        ...data,
        covidSeries: transpose(jsonCovid19),
        jsonSloData,
        jsonSloDataApi,
        computedSloData,
        computedAgeData,
        chartOptionsDeaths: { ...chartOptionsDeaths, series:
						[...dashedLines, ...transpose(jsonCovid19)]},
        chartOptionsAge: { ...chartOptionsAge, series:
					computedAgeData, xAxis: { categories: computeCategories(computedSloData.totalByAgeMale) } },
        fetched: true,
        currentSliderValue: Object.keys(computeSliderLabelsAge(jsonSloData)).length - 1,
        date: moment().subtract(1, "days").toDate()
      };
    });
  }, []);

  const playAgeInTime = () => {
    if (covidData.isPlaying) {
      return false;
    }

    let date = "2020-03-13";
    let lastDate = covidData.jsonSloData[covidData.jsonSloData.length - 2].date;
    const labels = computeSliderLabelsAge(covidData.jsonSloData);
    const interval = setInterval(() => {
      console.log(date);
      const index = Object.keys(labels).map((p) => moment(labels[p].date).format("YYYY-MM-DD")).indexOf(date);
      setCovidData((data) => {
        return {
          ...data,
          chartOptionsAge: {
            ...chartOptionsAge,
            series: computeAgeData(computeSloData(covidData.jsonSloData, date)),
            xAxis: {
              categories: computeCategories(computeSloData(covidData.jsonSloData, date).totalByAgeMale)
            }
          },
          date,
          currentSliderValue: index,
          isPlaying: true
        };
      });

      if (lastDate === date) {
        setCovidData((data) => ({...data, isPlaying: false}));
        return clearInterval(interval);
      }

      date = moment(date).add(1, "days").format("YYYY-MM-DD");
    }, 1000);
  };

  const setAge = (value) => {
    const labels = computeSliderLabelsAge(covidData.jsonSloData);
    const date = labels[value].date;
    const index = Object.keys(labels).map((p) => labels[p].date).indexOf(date);
    setCovidData((data) => {
      return {
        ...data,
        chartOptionsAge: {
          ...chartOptionsAge,
          series: computeAgeData(computeSloData(covidData.jsonSloData, date)),
          xAxis: {
            categories: computeCategories(computeSloData(covidData.jsonSloData, date).totalByAgeMale)
          }
        },
        date,
        currentSliderValue: index
      };
    });
  };

  const { fetched, currentSliderValue } = covidData;

  return (
    <div class={"statistics"}>
      {!fetched && <div className={"loading"}>
				Nalagam...
      </div>}
      {fetched &&
		<div>
		  <h2>STATISTIKA V TUJINI</h2>
		  <h4>Število smrti po državah</h4>
		  {fetched &&
				<HighchartsReact
				  highcharts={Highcharts}
				  options={covidData.chartOptionsDeaths}
				/>
		  }
		  <h2>STATISTIKA V SLOVENIJI</h2>
		  <div className={"slovenia-container"}>
		    <div className={"age-container"}>
		      <div>
		        <h4>Število okuženih oseb</h4>
		      </div>
		      {fetched && <Okuzeni data={covidData.jsonSloDataApi}/> }
		      <div className={"vir"}>
            Vir podatkov: <a href="https://covid-19.sledilnik.org/#/about" target="_blank"> COVID-19 sledilnik. </a>
		      </div>
		    </div>
		    <div className={"age-container"}>
		      <div>
		        <h4>Število hospitaliziranih oseb</h4>
		      </div>
		      {fetched && <Hospitalized data={covidData.jsonSloDataApi}/> }
		    </div>
		    <div className={"vir"}>
          Vir podatkov: <a href="https://covid-19.sledilnik.org/#/about" target="_blank"> COVID-19 sledilnik. </a>
		    </div>
		    <div className="region-container">
		      <h4>Število okužb po regijah (glede na št. prebivalcev občine)</h4>
		      <Map date={covidData.date} covidData={covidData}/>
		    </div>
		    <div className={"vir"}>
          Vir podatkov: <a href="https://covid-19.sledilnik.org/#/about" target="_blank"> COVID-19 sledilnik. </a>
		    </div>
		  </div>
		  <div className={"age-container"}>
		    <div className={"age-header"}>
		      <h4>Število okužb po starosti</h4>
		    </div>
		    {fetched &&
            <HighchartsReact
              highcharts={Highcharts}
              options={covidData.chartOptionsAge}
            />
		    }
		    <div className={"age-play"}>
		      <div className={"vir"}>
            Vir podatkov: <a href="https://covid-19.sledilnik.org/#/about" target="_blank"> COVID-19 sledilnik. </a>
		      </div>
		    </div>
		    <div className={"infected-header"}>
		      <h4>Število okužb na dan</h4>
		    </div>
		    {fetched && <OkuzeniDaily data={covidData.jsonSloDataApi}/> }
		    <div className={"vir"}>
          Vir podatkov: <a href="https://covid-19.sledilnik.org/#/about" target="_blank"> COVID-19 sledilnik. </a>
		    </div>
		  </div>
		</div>
      }
    </div>
  );
};
